<template>
  <transition mode="out-in" name="fade">
    <div class="size-modal-background" v-if="this.displayed === true">
      <div class="size-modal">
        <div class="title">
          Estimez la taille de votre box
        </div>
        <div class="content">
          <div class="size" v-for="(size, key) in this.sizes">
            <div>
              <img :src="'https://www.location-gardemeuble.fr/img/sizes/'+ key +'.png'" :alt="size.size" />
            </div>
            <div>
              <b>{{ size.name }}</b>
              <span>{{ size.size }}</span>
              <i>{{ size.desc }}</i>
            </div>
          </div>
        </div>
        <a class="close" @click="$emit('close')">×</a>
        <a href="/calculateur-de-volume" class="button">
          Calculer la taille
        </a>
      </div>
    </div>
  </transition>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { key } from "@/store";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

@Options( {
  props      : {
    displayed : {
      type     : Boolean,
      required : true
    }
  },
  components : {
    FontAwesomeIcon
  }
} )
export default class CalculModal extends Vue {
  store = useStore( key );
  props = Object.assign( {}, ...Object.entries( this.$props ).map( ( [key, value] ) => ({ [key] : value }) ) );
  sizes = [
    {
      name : "Petit",
      size : "1m² à 3m²",
      desc : "= Studio entre 10 et 30m²"
    },
    {
      name : "Moyen",
      size : "4m² à 6m²",
      desc : "= Logement entre 30 et 60m²"
    },
    {
      name : "Grand",
      size : "7m² à 9m²",
      desc : "= Logement entre 60 et 90m²"
    },
    {
      name : "Très grand",
      size : "10m² à 12m²",
      desc : "= Logement entre 80 et 120m²"
    }
  ];

  mounted()
  {
    this.size   = 0;
    this.amount = 12;
  }
}
</script>

<style lang="scss">
.size-modal-background
{
  position        : fixed;
  top             : 0;
  left            : 0;
  width           : 100vw;
  height          : 100vh;
  background      : rgba(0, 0, 0, 0.5);
  display         : flex;
  justify-content : center;
  align-items     : center;
  z-index         : 99999;
}

.size-modal
{
  display       : block;
  padding       : 1em;
  border-radius : 5px;
  box-shadow    : 0 0 10px rgba(0, 0, 0, 0.5);
  position      : relative;
  width         : 90%;
  max-width     : 600px;
  text-align    : left;
  background    : #F5F5F5;

  @media (max-width : 700px)
  {
    width : 97%;
  }
}

.size-modal div.title
{
  font-size : 1.2em;
  display   : block;
  margin    : 0 5px 15px;
}

.size-modal div.content
{
  display   : flex;
  flex-wrap : wrap;


  img
  {
    height   : 4em;
    position : relative;
    top      : 2px;
  }

  & > .size
  {
    display       : flex;
    width         : calc(50% - 10px);
    border-radius : 5px;
    margin        : 5px;
    padding       : 10px;
    background    : white;
    align-items   : center;

    @media (max-width : 700px)
    {
      width : calc(100% - 10px);
    }

    & > div:first-of-type
    {
      width      : 5em;
      text-align : center;
    }

    & > div:last-of-type
    {
      padding : 10px;

      & > b
      {
        display : block;
      }

      & > span
      {
        display       : inline-block;
        font-size     : 0.8em;
        margin        : 2px 0;
        background    : #F5F5F5;
        border-radius : 5px;
        padding       : 2px 5px;
      }

      & > i
      {
        display   : block;
        font-size : 0.75em;
      }
    }
  }
}

.size-modal a.close
{
  position  : absolute;
  top       : 2px;
  right     : 10px;
  cursor    : pointer;
  font-size : 1.5em;
}

.size-modal a.button
{
  display       : block;
  width         : calc(100% - 10px);
  border        : none;
  text-align    : center;
  background    : #2562E9;
  color         : white;
  cursor        : pointer;
  padding       : 0.5em;
  border-radius : 5px;
  margin        : 5px 5px 0;

  &:hover
  {
    background : #3B81F4;
  }
}

</style>